.sidebar {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  border: 1px solid #337ab7;
  display: none;
  max-height: 0;
  max-width: 355px;
  min-width: 355px;
  opacity: 0;
  left: 5px;
  position: absolute;
  top: 202px;
  z-index: 1000;
}

.sidebar-toggle {
  align-items: center;
  background-color: white;
  border: 3px solid #f8d65a;
  border-radius: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  margin: 10px 0 0 24px;
  padding: 4px;
  position: absolute;
  z-index: 1000;
}
.close-icon-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 5px 8px 0;
}

.scrollable-sidebar-content {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}

.sidebar-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 4px 10px;
}

.close-sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px 2px;
  overflow: hidden;
}

.layer-image {
  height: 30px;
  width: 30px;
}

.layer-button-text {
  color: #337ab7;
  font-weight: bold;
  letter-spacing: 0.7px;
  padding-left: 10px;
}

.ui.menu.two.item .item {
  background-color: white;
  border-bottom: 1px solid #337ab7;
  color: black;
}

.layer-container,
.result-container {
  margin-bottom: 10px;
}

.layer-container {
  margin: 0 1.1rem 1.1rem;
}

.legend {
  max-width: 100%;
}

.sidebar__tabtext {
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .sidebar {
    left: 0;
    max-width: 100%;
    min-width: 100%;
    top: 0;
  }
  .sidebar-toggle {
    margin-left: 10px;
  }
  .scrollable-sidebar-content {
    max-height: calc(50vh - 90px);
  }
}
