.app-header {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  height: 120px;
}

.header-logo {
  margin: 0 32px 0 0;
  max-width: 150px;
  min-width: 150px;
  order: 1;
  padding: 10px 0 0 24px;
}

.header-text-box,
.apo-header-text-box {
  max-width: 600px;
  order: 2;
  padding: 10px 0 0 0;
  width: 65%;
}

.header-title,
.apo-header-title {
  color: #4f4f4f;
  font-weight: 500;
  margin: 0;
}

.header-text,
.apo-header-text {
  font-size: 16px;
  font-weight: 300;
}

.header-links {
  flex-grow: 2;
  font-size: 16px;
  order: 3;
  padding: 10px 24px 16px 0;
}

#headerDropdown {
  max-height: 90vh;
  overflow-y: auto;
}

.header-link {
  color: #4f4f4f;
}

@media screen and (max-width: 992px) {
  .header-text,
  .apo-header-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding-bottom: 8px;
  }
  .header-text-box,
  .apo-header-text-box {
    width: initial;
  }

  .header-text {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .apo-header-text-box {
    order: 4;
    padding: 5px 0 0 24px;
    width: 100%;
  }
  .apo-header-text,
  .apo-header-text-link {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .apo-header-title {
    font-size: 22px;
  }
  .header-text-box {
    order: 4;
    padding: 5px 0 0 24px;
    width: 100%;
  }
  .header-text-link {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .apo-header-title {
    font-size: 18px;
  }
}
