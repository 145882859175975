.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  border: 1px solid #aaa;
  border-radius: 4px;
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 300;
  height: 40px;
  padding: 10px 20px;
  width: 336px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4f4f4f;
  font-family: "Barlow Semi Condensed";
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4f4f4f;
  font-family: "Barlow Semi Condensed";
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  background-color: #fff;
  border: 1px solid #aaa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: block;
  max-height: calc(100vh - 202px);
  overflow-y: auto;
  position: absolute;
  top: 56px;
  width: 336px;
  z-index: 1001;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #5897fb;
}

.react-autosuggest__section-title {
  background-color: whitesmoke;
  border-bottom: 1px dashed gray;
  color: #777;
  font-size: 18px;
  padding: 20px 0 10px 10px;
}

.ais-Highlight-highlighted {
  background-color: #ffda06;
}

@media screen and (max-width: 992px) {
  .react-autosuggest__suggestions-container--open {
    /*max-height: calc(100vh - 209px);*/
    min-width: 0;
  }
}

@media screen and (max-width: 480px) {
  /* When in apo state the searchbar must grow until it reaches the state changer butten */
  .ais-InstantSearch__root {
    flex-grow: 1;
  }
  .react-autosuggest__suggestions-container--open {
    /* max-height: calc(100vh - 207px);*/
    width: 100%;
  }
  .react-autosuggest__input {
    width: 100%;
  }
}
