.search {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  height: 72px;
  padding: 16px 24px;
}

.apo-search {
  background-color: #f8d65a;
  border-radius: 5px;
}

.apo-state-switch {
  margin: 0 0 0 8px !important;
}

@media screen and (max-width: 480px) {
  .search {
    justify-content: initial;
  }

  .search-title {
    font-size: 16px;
  }
}
