#oereb-map,
#apo-map {
  cursor: pointer;
  height: calc(100vh - 192px);
}

#pop-up-title {
  margin: 8px 0;
}
/* Primary Button in Popup */
.btn-spacer {
  display: block;
  height: 10px;
}

.popup-split {
  margin: 5px 0 8px 0;
}
.leaflet-container {
  background-color: white;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: rgba(255, 255, 255, 0.9) !important;
  font-family: barlow semi condensed;
  font-size: 15px;
}

.leaflet-popup-content-wrapper {
  overflow-y: auto;
}

.leaflet-popup-close-button {
  color: #474747 !important;
  padding: 4px 16px 0 0 !important;
}

.red {
  color: red;
}

.small {
  font-size: 0.95em;
}

.apoButtonContainer__hintText {
  padding: 0 0 4px 0;
}

.map-placeholder {
  width: 100%;
  height: calc(100vh - 192px);
  background-size: 16px 16px;
  background-image: linear-gradient(to right, gainsboro 1px, transparent 1px),
    linear-gradient(to bottom, gainsboro 1px, transparent 1px);
}

/* Dropdown Select in the popup */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #efefef;
  border: none;
  border-radius: 3px;
  padding: 0.5em 2em 0.5em 1em;
  font-size: 1em;
  width: 100%;
}
.select-container {
  background-color: darkgrey;
  display: inline;
  position: relative;
}
.select {
  font-family: barlow semi condensed;
  background-color: lightgray;
}
.select-container:after {
  content: "";
  height: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.select-container:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid black;
  opacity: 0.5;
  right: 0.75em;
  top: 0.3em;
}
select::-ms-expand {
  display: none;
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  .leaflet-popup-content-wrapper {
    max-height: 350px;
  }
}
