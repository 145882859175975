.compare__container {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0 0;
}

.compare__title {
  color: #4f4f4f;
  font-size: 28px;
  font-weight: bold;
  padding: 24px 8px 20px 0px;
  width: 49%;
}

.comparemaps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.comparemap__container {
  margin-bottom: 16px;
  width: 49%;
}
.comparemap__map {
  background-color: gainsboro;
  height: 400px;
}

.comparemap__groupname {
  background-color: gainsboro;
  color: #4f4f4f;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
}

.comparemap__groupname-projected {
  background-color: #f8d65a;
}

.metadata__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.metadata__header {
  color: #4f4f4f !important;
  margin: 0 0 8px 0;
}
.metadata__date {
  margin: 0 0 12px 0;
}
.metadata__item {
  padding-right: 12px;
}

.metadata__item:last-of-type {
  padding: 0;
}

.metadata__title {
  color: #4f4f4f !important;
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 8px !important;
}

.metadata__text {
  color: #4f4f4f !important;
  font-size: 17px;
}
.metadata__link {
  font-weight: 600;
}

/* Mobile Styles */

@media screen and (max-width: 850px) {
  .metadata__item {
    width: 33%;
  }
  .metadata__container {
    justify-content: initial;
  }
}

@media screen and (max-width: 600px) {
  .compare__container {
    display: none;
  }
  .comparemap__container {
    margin: 16px 0;
    width: 100%;
  }
  .metadata__item {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .metadata__item {
    width: 100%;
  }
}
